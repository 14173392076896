(function($){$.fn.extend({device_confirm:function(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;}})})(jQuery);(function($){$.fn.extend({system_confirm:function(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}}})})(jQuery);if(window.innerWidth>956){$(".tab_index").mouseenter(function(){$("#submenu_container").addClass("active"); $(".submenu").eq($(this).index(".tab_index")).addClass("active").siblings(".submenu").removeClass("active");});$("#submenu_container").mouseleave(function(){$("#submenu_container").removeClass("active");$(".submenu").removeClass("active");});};function system_confirm(){var userAgentInfo = navigator.userAgent;if(userAgentInfo.indexOf("Win")>-1){return "win"}else if(userAgentInfo.indexOf("Mac")>-1){return "mac"}};function device_confirm(){var userAgentInfo = navigator.userAgent;var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];var flag = true;for (var v = 0; v < Agents.length; v++) {if (userAgentInfo.indexOf(Agents[v]) > 0) {flag = false;break;}}return flag;};
(function () {
    var isMac,device_com;
    if(system_confirm()=="mac"){isMac=true}
    else if(system_confirm()=="win"){isMac=false}
    if(device_confirm()==true){device_com=true}
    else if(device_confirm()==false){device_com=false}
    if (isMac && device_com == true) {
        $('.mac_btn_box').css({"display":"flex"});
        $('.mac_btn').css({"display":"block"});
        $('.win_btn_box').css({"display":"none"});
        $('.win_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
    } else if (!isMac && device_com == true) {
        $('.win_btn_box').css({"display":"flex"});
        $('.win_btn').css({"display":"block"});
        $('.mac_btn_box').css({"display":"none"});
        $('.mac_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
      
    } else if (device_com == false) {
        $('.pc_btn').css({"display":"none"});
        $('.mobile_btn').show();
    };
})();

//header
(function(){
    var _length = $('.featured_list .info_i').length;
    for(var i =0;i<_length;i++){
        $('.featured_list .info_i').eq(i).attr('title',$('.featured_list .info_i').eq(i).html())
    }
})();
function resizeHeader(){
    $('.header>div>ul>li').unbind();
    $('.menu').unbind();
    $('.header>div>ul>li').removeClass('active');
    if($(window).width()>1200){
		$('.header>div>ul').show();
        $('.header>div>ul>li').hover(function(){
            $(this).children('div').fadeIn();
        },function(){
            $(this).children('div').hide();
        });
        // 会员
        $('.header .login_btn .login_box').unbind('hover');
        $('.header .login_btn .login_box').hover(function(){
            $('.header .login_btn .login_box ul').show();
        },function(){
            $('.header .login_btn .login_box ul').hide();
        });
    }else{
        $('.header_box .toggle').removeClass('active')
		$('.header>div>ul').hide();
        $('.header>div>ul>li').click(function(e){
            e=e||window.event,window.event?e.stopPropagation():window.cancelBubble=!0;
            $(this).toggleClass('active').siblings('li').removeClass('active');
        });
        $('.menu').click(function(e){
            e=e||window.event,window.event?e.stopPropagation():window.cancelBubble=!0;
        });
        $('.header_box>ul>li>.menu>div .title').click(function(){
            $(this).parent().toggleClass('active').siblings().removeClass('active');
        });
        // 会员
        $('.header .login_box').unbind('click');
        $('.header .login_box').click(function(){
            if($('.header_box .toggle').attr('class').indexOf('active')>-1){
                $('.header .toggle').removeClass('active');
                $('.header_box>ul').hide();
            }
            if($('.header .login_box ul').css("display")=='none'){
                $('.header .login_box ul').show();
                $(".header_bg").show()
            }else{
                $('.header .login_box ul').hide();
                $(".header_bg").hide()
            }
        });
    }

}
resizeHeader();
var windowWidth=$(window).width();
$(window).resize(function(){
    if(windowWidth!=$(window).width()){
        resizeHeader();
        windowWidth=$(window).width()
    }
});
$('.header_box .toggle').click(function(){
    $(this).toggleClass('active');
    $('.header_box>ul').toggle();
    $('.header .login_box ul').hide();
    $(".header_bg").hide()
});
$(".header_bg").click(function(){
    $(this).hide();
    $('.header .login_box ul').hide();
});